import { useRouter } from "next/router";
import Script from "next/script";

const BUNDLE_LINK = process.env.NEXT_PUBLIC_BUNDLE_LINK;

export type AtlasSnippetProps = {
  appId: string;
  chatting?: boolean;
  recording?: boolean;
  customerPortal?: boolean;
  baseUrl: string;
};

export default function AtlasSnippet({
  appId,
  chatting,
  recording,
  customerPortal,
  baseUrl,
}: AtlasSnippetProps) {
  const router = useRouter();

  if (!chatting && !recording && !customerPortal) return null;

  if (typeof window !== "undefined") {
    const onAtlasWidgetLinkClick = (
      props: {
        href: string;
        categorySlug?: string;
        articleSlug?: string;
        articleSection?: string;
      },
      event: Event
    ) => {
      if (props.categorySlug) {
        event.preventDefault();
        router.push(`${baseUrl}/categories/${props.categorySlug}`);
      } else if (props.articleSlug) {
        event.preventDefault();
        router.push(
          `${baseUrl}/articles/${props.articleSlug}${
            props.articleSection ? `#${props.articleSection}` : ""
          }`
        );
      }
    };
    Object.assign(window, { onAtlasWidgetLinkClick });
  }

  // widget SDK needs to run for Customer portal. Hiding the bubble if chat is turned off
  return (
    <>
      {/* For clients that do login by setting cookie from different subdomain, we need to reset localStorage */}
      <Script id="script-atlas-identity-fix">{`
        ((w,i)=>{try{var l=w.localStorage,k='atlasIdentity',v=l.getItem(k),d,r,c;if(!v)return;r=()=>{l.removeItem(k);l.removeItem('atlasLocation')};d=JSON.parse(v);if(d.appId!==i)return r();c=w.document.cookie.split('; ').find(r=>r.startsWith(\`\${k}\${i}=\`));if(!c)return r();if(c.split('=')[1]!==d.atlasId)return r()}catch(error){console.error(error)}})(window,"${appId}");
      `}</Script>
      <Script id="script-atlas-snippet">{`
          (()=>{"use strict";const t={appId:"${appId}",v:2,autorun:{identityWaitTime:0, recording:{enabled:${!!recording}},chat:{enabled:${!!chatting},openIncoming:${!!chatting},hideBubble:${!chatting}},callbacks:{onLinkClick:(props, event)=>window.onAtlasWidgetLinkClick(props,event)}},q:[],call:function(){this.q.push(arguments)},start:function(o){this.autorun=o||!0},identify:function(t){this.q.push(["identify",t])}};window.Atlas=t;const e=document.createElement("script");e.async=!0,e.src="${BUNDLE_LINK}";const s=document.getElementsByTagName("script")[0];s.parentNode?.insertBefore(e,s);if(typeof window.onAtlasRegistered=='function')window.onAtlasRegistered(t)})();
      `}</Script>
    </>
  );
}

/**
 * Original Atlas Identity Fix Script
 *
 * ((w,i) => {
 *   try {
 *     var l = w.localStorage,
 *       k = 'atlasIdentity',
 *       v = l.getItem(k),
 *       d, r, c;
 *
 *     if (!v) return;
 *
 *     r = () => {
 *       l.removeItem(k);
 *       l.removeItem('atlasLocation');
 *     };
 *
 *     d = JSON.parse(v);
 *     if (d.appId !== i) return r();
 *
 *     c = w.document.cookie.split('; ').find(r => r.startsWith(`${k}${i}=`));
 *     if (!c) return r();
 *     if (c.split('=')[1] !== d.atlasId) return r();
 *   } catch (error) {
 *     console.error(error);
 *   }
 * })(window,appId);
 */
